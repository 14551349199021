<template>
    <div class="page">
        <Head :type="0">

            <template #title> <div> 等级规则</div> </template>
               
           
        </Head> 
        <div class="main">

            <div class="box">
                <ul>
                    <li> 
                        <div class="title"> 会员等级介绍</div>

                        <div class="content">
                            会员等级是基于用户一定时期内使用Win生活业务累积获取的成长值决定，用户达到相应的等级门门槛即可升级。用户可以通过消费购物、生活缴费和金融理财来获取成长值，从而提升等级。
                        </div>


                    </li>

                     <li> 
                        <div class="title"> 等级有效期</div>

                        <div class="content">
                         会员等级有效期为1年（365天），若用户升级，新等级有效期从升级当天开始计算。
                            若您没有在当前有效期内升级，新等级有效期会在当前等级有效期到期日的次日重新计算并变更为新的等级;同时，你在当前等级有效期变更前获取的成长值【含财富奖励的成长值（以成长值到账时间计算）】会自动清零。新等级有效期内获取的成长值，将计算在新的等级有效期内。
                        </div>

                    </li>

                     <li> 
                        <div class="title"> 升降级规则</div>

                        <div class="content">
                           用户在等级有效期内成长值达到升级门槛值，新等级立即生效。等级到期后，用户等级将根据上个有效期内累计的成长值重新计算。
                            若跨等级有效期变更的交易出现退款，成长值会在新等级有效期的成长值中扣除(因为等级有效期前获取的成长值已计入了上个等级有效期并影响用户升降级)。若当前等级有效期成长值不足抵扣跨等级有效期的交易退款的成长值，则有多少扣多少，扣至为零后，后续获取的成长值不会再抵扣。
                        </div>

                    </li>

                    <li> 
                        <div class="title"> 成长值说明</div>

                        <div class="content">
                            用来评定用户会员等级的值称为成长值;用户可通过消费购物、生活缴费、金融理财等方式获取成长值，从而提升会员等级。用户升级或当前等级有效期到期，成长值会自动清零。
                            用户每日最多获取1000成长值（不含通过金融理财获取部分），当获取成长值达到日限、月限时，超过限额部分的成长值不再发放;若发生退款，日、月限会被释放，但未获取的成长值不补发。请勿违规刷成长值（包括但不限于虚假交易、炒信、恶意退款、拆单、套现），如发现存在违规行为，我们有权取消你获得成长值的资格。
                        </div>

                    </li>
                    
                     <li> 

                        <div class="title"> 等级权益</div>

                        <div class="content">
                            不同的会员可享受该等级专属权益。请您理解，等级权益提供方可能会根据运营等实际情况对权益进行动态调整，具体权益届时以页面展示为准。
                        </div>

                    </li>

                    <li> 

                        <div class="title"> 会员等级线程图 </div>

                        <div class="content">
                            用户成长值达到对应升级门槛，即立即升级。有效期结束后，用户的等级将根据上个有效期内已累计积分重新计算等级。
                        </div>

                    </li>

                </ul>
                <div class="fotter">
                <div class="bottom" >

                    <div class="word"> 成长值</div> 
                    
                    <div class="line" style="left:  1.253333rem;"> 
                        
                                 <!-- 银牌等级积分 -->
                                <div>银牌</div> <p></p> <span>0</span> </div>
                   
    
                    <div class="line" style="left:2.293333rem;">
                        
                                 <!-- 金牌等级积分 -->
                                 <div>金牌</div><p></p><span>{{$route.query.level1}}</span></div>
                        
                        
                    <div class="line" style="right:2.133333rem;">

                                  <!-- 钻石等级积分 -->
                                  <div>钻石</div> <p></p>  <span>{{$route.query.level2}}</span>
                       
                      
                    </div>
                 </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Head from '../../../components/head/index.vue'
// import { getLevel } from "../../../api/maker/vip";

    export default {
        data(){ 

            return{ 
                    level:null

            }
        },
        components:{Head},
        created(){ 
            this.setRem()
            // try{ 
            //     window.webkit.messageHandlers.statusBar.postMessage(JSON.stringify({}))
            // }catch{ 
            //     //
            // }

            // getLevel({id:this.$store.state.userDate.id}).then(res=>{ 

            //     this.level=res.data.data
            // })
        }
    }
</script>

<style lang="less" scoped>
.page{ 
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    .main{ 
        flex: 1;
        background-color: #f6f6f6;
        overflow: auto;
        box-sizing: border-box;
        .box{ 
            padding: .4rem;
             margin-bottom: constant(safe-area-inset-bottom);
            margin-bottom: env(safe-area-inset-bottom);
            
            li{ 
                padding:  .373333rem 0;
                    color: #333;
                    border-bottom: 1px solid #d6d6d6;

                .title{ 
                    font-size: .426667rem;
                    margin-bottom: .266667rem;
                    font-weight: 600;
                }
                .content{ 
                    font-size: .373333rem;
                }
            }
            li:nth-last-of-type(1){ 
                border: none;
            }
            .fotter{
                  padding: .666667rem 0;
            }
          
            .bottom{ 
                width: 100%;
                height: 1px ;
                background-color: #999;
                position: relative;
                color: #333;
                font-size: .32rem;
                .line{ 
                    position: absolute;
                    height:.213333rem ;
                    width: .026667rem;
                    background-color: #999;
                    top: 0;
                    p{ 
                        width: .133333rem;
                        height: .133333rem;
                        background-color: #999;
                        border-radius: 50%;
                        position: absolute;
                        bottom: -0.066667rem;
                        left: .013333rem;
                        transform: translateX(-50%);
                    }
                    div{ 
                        position: absolute;
                        top: -0.533333rem;
                        white-space: nowrap;
                        left: 0;
                        transform: translateX(-50%) scale(0.83);
                        
                    }
                    span{ 
                        position: absolute;
                        bottom: -0.533333rem;
                        left: 0;
                        transform: translateX(-50%) scale(0.83);
                    }
                }
                .word{ 
                    transform: scale(0.83);
                    position: absolute;
                    top: .266667rem;
                    left: 0;
                }
            }
        }
    }
}
</style>